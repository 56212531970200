import styled from 'styled-components'
import { Flex } from '@renderbus/common/components'
import { Media, spacing, typography } from '@renderbus/common/theme'
import DashImg from '../images/start-new-year/dash.png'
import ArrowRight from '../images/start-new-year/arrow-right.png'
import ArrowLeft from '../images/start-new-year/arrow-left.png'
import ArrowDown from '../images/start-new-year/arrow-down.png'

import CardBgMoible from '../images/xmas/card-bg-mobile.png'

import Arrow from '../images/start-new-year/red-arrow-hover.png'
import CardBg from '../images/start-new-year/card-bg.png'
import ArrowHover from '../images/xmas/arrow-hover.png'

export const BannerContainer = styled.div`
  position: relative;
  padding-top: 60px;
  height: 600px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    a {
      width: 160px;
      font-size: ${typography.h4};
      margin-top: ${spacing.base};
    }
  }
`
export const Badge = styled.img`
  position: absolute;
  top: 60px;
  left: 13.5%;
  ${Media.lessThan(Media.small)} {
    width: 25%;
  }
`
export const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: ${spacing.base};
  ${Media.lessThan(Media.small)} {
    width: 90%;
    img:first-child {
      width: 100%;
    }
  }
`
export const TitleBadge = styled.img`
  position: absolute;
  top: -110px;
  right: -140px;
  ${Media.lessThan(Media.small)} {
    width: 30%;
    right: 0;
  }
`
export const Content = styled.div`
  margin-top: 60px;
  margin-bottom: 120px;
  ${Media.lessThan(Media.small)} {
    font-size: 15px;
    margin-top: 40px;
    margin-bottom: 50px;
    p {
      margin: 5px auto;
    }
  }
`

export const PageWrapper = styled.div`
  width: 100%;
  background: #b22f17;
  overflow: hidden;
`

export const ContentWrapper = styled.div`
  margin: 0 auto;
  color: white;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    margin-bottom: 50px;
  }

  .title-img {
    display: block;
    width: 472px;
    margin: 0 auto;
    &.t1 {
      margin-top: 90px;
    }
    &.t2 {
      margin-top: 105px;
    }
    &.t3 {
      margin-top: 140px;
    }
    ${Media.lessThan(Media.small)} {
      width: 280px;
      &.t1,
      &.t2,
      &.t3 {
        margin-top: 45px;
      }
    }
  }

  .xmas-content {
    display: flex;
    justify-content: center;
    &.c1 {
      margin-top: 70px;
    }
    &.c2 {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
    }
    &.c3 {
      margin-top: 60px;
      margin-bottom: 120px;
    }
    ${Media.lessThan(Media.small)} {
      &.c1 {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
      }
      &.c2 {
        display: flex;
        flex-direction: column;
        margin-top: 35px;
      }
      &.c3 {
        margin-top: 40px;
        margin-bottom: 55px;
      }
    }
  }

  .gold-card {
    position: relative;
    width: 336px;
    height: 170px;
    padding: 0 20px;
    color: #b22f17;
    background: url(${CardBg}) center / auto 170px;
    & + .gold-card {
      margin-left: 18px;
    }
    ${Media.lessThan(Media.small)} {
      width: 100%;
      height: 75px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      background: url(${CardBgMoible}) center / 100% 75px;
      & + .gold-card {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .xmas-price {
      position: relative;
      top: 10px;
      font-size: 48px;
      font-weight: bold;
      margin-left: 20px;
      &::before {
        position: absolute;
        bottom: 10px;
        margin-left: -20px;
        content: '￥';
        font-size: 24px;
      }
      ${Media.lessThan(Media.small)} {
        width: 75px;
        top: 0;
        font-size: 30px;
        margin-left: 15px;
        white-space: nowrap;
        &::before {
          font-size: 18px;
          bottom: 5px;
          margin-left: -15px;
        }
      }
    }

    .xmas-discount {
      font-size: 18px;
      font-weight: 500;
      ${Media.lessThan(Media.small)} {
        width: 90px;
        font-size: 15px;
        margin-left: 12px;
        margin-top: 0;
        span {
          display: block;
          white-space: nowrap;
        }
        .discount-dot {
          display: none;
        }
      }
    }

    .xmas-detail {
      font-size: 12px;
      ${Media.lessThan(Media.small)} {
        display: none;
      }
    }

    .xmas-button {
      position: absolute;
      width: 120px;
      padding: 0 15px;
      line-height: 30px;
      font-size: 16px;
      border-radius: 15px;
      border: 1px solid #b22f17;
      bottom: 12px;
      cursor: pointer;
      background: url(${Arrow}) no-repeat 90px 10px / auto 10px;
      transition: background-color 300ms, color 300ms;
      &:hover {
        background-color: #b22f17;
        background-image: url(${ArrowHover});
        color: #fff;
      }

      ${Media.lessThan(Media.small)} {
        right: 15px;
        bottom: 20px;
        width: 110px;
      }
    }
  }

  .card-2 {
    position: relative;
    width: 684px;
    height: 300px;
    color: #b22f17;
    padding: 30px 40px;
    background: linear-gradient(0deg, #ffae67 0%, #f6c48e 99%);
    border-radius: 20px;
    .card-2-welfare {
      position: absolute;
      top: -1px;
      right: 0;
    }
    .welfare-title {
      font-size: 30px;
    }
    .welfare-flow {
      display: block;
      margin: 32px auto 0;
    }
    .welfare-text-flow {
      margin-top: 27px;
      font-weight: bold;
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      span {
        text-align: center;
      }
      .text-flow-center {
        position: absolute;
        left: 0;
        right: 0;
      }
      &.welfare-text-flow-2 {
        span:last-child {
          display: inline-block;
          width: 108px;
        }
      }
    }
    ${Media.lessThan(Media.small)} {
      width: 100%;
      height: auto;
      border-radius: 20px;
      padding: 25px 20px 30px;
      & + .card-2 {
        margin-top: 20px;
      }
      .card-2-welfare {
        width: 100px;
      }
      .welfare-title {
        font-size: 18px;
        font-weight: bold;
      }
      .welfare-flow {
        width: 100%;
        margin-top: 25px;
      }
      .welfare-text-flow {
        margin-top: 15px;
        font-weight: normal;
        width: calc(100% + 30px);
        margin-left: -20px;
        span {
          font-size: 14px;
        }
        .text-flow-center {
          margin-left: 10px;
        }
        &.welfare-text-flow-1 {
          span:last-child {
            display: inline-block;
            width: 56px;
            margin-right: 10px;
          }
        }
        &.welfare-text-flow-2 {
          span:last-child {
            display: inline-block;
            width: 85px;
          }
        }
      }
    }
  }
`
export const Detail = styled(Flex)`
  justify-content: space-between;
  padding: 0 ${spacing.large};
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    padding: 0;
    > div {
      margin-bottom: 65px;
    }
  }
`
export const Section = styled.section`
  font-size: 18px;
  color: #fff;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
    img {
      width: 100%;
      margin-bottom: ${spacing.large};
    }
  }
`
export const Circle = styled(Flex)`
  width: 120px;
  height: 120px;
  border-radius: 50% 50%;
  background: linear-gradient(rgba(19, 203, 117, 0.05), rgba(19, 203, 117, 0.2));
  align-items: center;
  position: relative;
  img {
    margin-bottom: 0;
    position: relative;
  }
  &.circle-0::after,
  &.circle-1::after,
  &.circle-2::after {
    content: '';
    background: url(${DashImg}) no-repeat;
    background-size: 215px 1px;
    position: absolute;
    width: 215px;
    height: 1px;
    top: 50%;
    right: -215px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 90%;
    }
    &.circle-0::after {
      background: url(${ArrowRight}) no-repeat;
      background-size: 98% 10px;
      width: 98%;
      height: 10px;
      right: -98%;
    }
    &.circle-1::after {
      background: url(${ArrowDown}) no-repeat;
      left: 50%;
      width: 10px;
      height: 100%;
      background-size: 10px 100%;
      top: 100%;
    }
    &.circle-2::after {
      background: url(${ArrowLeft}) no-repeat;
      background-size: 98% 10px;
      width: 98%;
      left: 98%;
      height: 10px;
      right: 0;
    }
  }
`
export const Tips = styled.p`
  margin: -30px 0 50px 0;
  ${Media.lessThan(Media.small)} {
    margin-top: -10px;
  }
`
